.pills .pill-button { 
  box-sizing: border-box;
  border-radius: 3px;
  height: 40px;
  color: black;
  font-weight: 700;
  border-color: var(--pill-button-border-color)
}

.pills .pill-button:hover { 
  color: black;
  background-color: inherit;
  border-color: var(--pill-button-border-hover-color)
}

.pills .pill-button.active {
  color: var(--pill-active-button-text-color);
  border-color: var(--pill-active-button-border-color);
  transition: 0;
}