main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--main-background-color);
  margin: 0;
}

main .main-outlet {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--main-background-color);
  margin: 20px 20px 0 20px;
}

main .main-footer {
  height: 64px;
  border-top: 1px solid var(--footer-border-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px 0 20px;
  font-size: var(--footer-font-size);
  font-weight: var(--footer-font-weight);
  color: var(--inactive-font-color);
}

main .main-footer {
  & > div:not(:last-child)::after {
    content: "|";
    margin-left: 10px;
    margin-right: 10px;
    color: var(--inactive-font-color);
  }
}

main .main-page {
  width: 100%;
  height: 100%;
}