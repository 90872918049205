aside {
  width: 210px;
  background-color: var(--navigation-background-color);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-shrink: 0;

  // Navigation Header
  .nav-header {
    position: relative;
    padding: 0 10px 0 20px;
    height: 35px;
    line-height: 35px;
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    color: var(--navigation-header-color);
    overflow: ellipsis;
  }

  .nav-header-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiIconButton-root {
      margin-left: auto;
      color: var(--navigation-header-color);
    }
  }

  // Navigation Links
  ul {
    margin: 0;
    padding: 8px 0;
    width: 100%;
    list-style: none;
  }

  .nav-link {
    position: relative;
    padding: 0 20px;
    height: 45px;
    line-height: 45px;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--navigation-link-color);
    flex-grow: 1;
  }

  li:has(.nav-link) {
    &:hover {
      background-color: var(--navigation-background-hover-color);
      color: var(--white);
    }

    &.active {
      background-color: var(--navigation-background-active-color);
      color: var(--white);
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiIconButton-root {
      margin-left: auto;
      color: white;
    }
  }

  // Sub Menu
  .sub-menu {
    background-color: var(--navigation-sub-menu-background-color);
    padding: 0;

    .nav-link {
      padding-left: 36px;
    }
  }

  // General Elements
  a {
    display: block;
    text-decoration: none;
  }

  hr {
    margin: 0 14px;
    border-color: var(--box-border-color);
  }

  .header-container:last-of-type hr {
    display: none;
  }
}
