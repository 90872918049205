.handsontable {
  font-family: var(--font-family);
  color: var(--gray1);
  font-size: 16;
  font-weight: 400;
  /* Set default font size, color, and weight */
  overflow: visible !important; /* ✅ Prevents dropdown clipping */
  --ht-border-color: var(--gray5); /* ✅ Override border color */
}

/* Make odd rows white */
.handsontable tr:nth-child(odd) td {
  background-color: var(--white);
}

/* Make even rows gray */
.handsontable tr:nth-child(even) td {
  background-color: var(--gray8);
}

.handsontable td {
  font-size: 16px;
  padding: 0 16px;
  vertical-align: middle;
}

.handsontable td {
  position: relative;
}

.handsontable td .numeric {
  text-align: right;
}

.handsontable thead th {
  background-color: var(--gray9);
  color: var(--gray1);
  font-weight: 700;
  font-size: 13px;
  vertical-align: middle;
  text-align: left;
  padding: 0 16px;
  border-bottom: 1px solid var(--gray5);
}

/* Headers have an inner div with extra padding. Remove horizontal padding from it */
.handsontable thead th .relative {
  padding: 2px 0px;
}

/* ✅ Make autocomplete dropdown rows taller */
.handsontable.autocompleteEditor tbody tr {
  height: 40px !important; /* Adjust row height */
}

/* ✅ Change row background color */
.handsontable.autocompleteEditor tbody tr:nth-child(odd) {
  background-color: #f8f9fa !important; /* Light gray for odd rows */
}

.handsontable.autocompleteEditor tbody tr:nth-child(even) {
  background-color: #ffffff !important; /* White for even rows */
}

/* ✅ Highlight hovered row */
.handsontable.autocompleteEditor tbody td:hover {
  background-color: var(--button-primary-hover-color) !important; /* Blue hover */
  color: var(--button-primary-hover-text-color) !important;
}

/* ✅ Add a shadow to the autocomplete dropdown */
.handsontable.autocompleteEditor {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3) !important; /* Adds depth */
  border-radius: 6px !important; /* Rounded corners */
}

/* Custom class added to indicate disabled cells */
.htDisabled {
  background-color: rgba(0, 0, 0, 0.1) !important; /* Darken the background color */
}

.htDisabled::before {
  content: '-';
}

.handsontable td.htInvalid {
  background-color: var(--red-cell-background) !important;
}